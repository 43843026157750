import * as React from "react"
import "twin.macro"
import { MDXRenderer } from "gatsby-plugin-mdx"

export default function Announcement({ announcement }) {
  return (
    <div tw="prose lg:prose-lg mx-auto my-6 sm:my-12 md:my-20">
      <h1>{announcement.title}</h1>
      <p>
        {announcement.category}. {announcement.date}
      </p>

      <MDXRenderer>{announcement.body}</MDXRenderer>
    </div>
  )
}
