import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Announcement from "../components/announcements/Announcement"
import "twin.macro"
import AnnouncementInfo from "../models/announcement"

export default function Template(props) {
  const { mdx } = props.data // data.markdownRemark holds your post data

  return (
    <Layout>
      <SEO title={mdx.frontmatter.title} description={mdx.frontmatter.blurb} />

      <Announcement
        announcement={
          new AnnouncementInfo(
            mdx.frontmatter.title,
            mdx.frontmatter.category,
            mdx.frontmatter.date,
            mdx.body
          )
        }
      />
    </Layout>
  )
}
export const pageQuery = graphql`
  query($id: String!) {
    mdx(frontmatter: { path: { eq: $id } }) {
      body
      frontmatter {
        date(formatString: "MMM DD, YYYY")
        path
        title
        category
        thumbnail
        blurb
      }
    }
  }
`
